
/* eslint-disable */
let paths = require.context('./', false, /\/.*\.js$/); //获取当前全部路由
let modules = {}; //各个模块的集合
let moduleName = [];
paths.keys().map(item => { //获取本级目录下的全部配置参数 
    if (item != './index.js') {
        moduleName.push(item.replace(/(\.\/|\.js)/g, '').toLowerCase());
        return paths(item).default;
    }
    return '';
}).forEach((item, index) => {
    modules[moduleName[index]] = item;
});
export default modules
